.Planes{
	display:flex;
	flex-direction: column;
	gap: 2rem;
	padding: 0 2rem;

}


.planes-header{
	display: flex;
	gap: 5rem;
	font-weight: bold;
	font-size: 3rem;
	justify-content: left;
	color: white;
	text-transform: uppercase;
	font-style: italic;
}



.planes-categories{
	display: flex;
	gap: 1rem;
	
}

.category{

	display: flex;
	flex-direction: column;
	background-color: gray;
	padding: 2rem;
	gap: 1rem;
	color: white;
	justify-content: space-between;

}

.category>:nth-child(1){
	width: 2rem;
	height: 2rem;fill: white;
}

.category>:nth-child(2){
	font-size: 1rem;
	font-weight: bold;
}
.category>:nth-child(3){
	font-size: 0.9rem;
	line-height: 25px;
}
.join-now{
	display: flex;
	align-items: center;gap: 2rem;
}

.join-now>img{
	width: 1rem;
}

.category:hover{
	background: var(--planCard);
	cursor: pointer;
}

.metris{
	display:flex;
	gap: 2rem;
	background-color: var(--caloryCard);
	border-radius: 5px;
	padding: 1rem;
	width: fit-content;
	position: absolute;
	top: 100rem;
	right: 23rem;

}