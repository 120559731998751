.Servicios{
	display: flex;
	gap: 2rem;
	padding: 0 2rem;
}

.left-t{
	display: flex;
	flex: 2 1;
	gap: 3rem;
	flex-direction: column;
	color: white;
}
.left-t>:nth-child(1){
	font-weight: bold;
	font-size: 2.5rem;
	
}
.left-t>:nth-child(4){

	text-transform: none;
	text-align: justify;
	letter-spacing: 2px;
	line-height: 40px;
}

.right-t{
	
	flex: 1.5;
	position: relative;
}

.right-t>img{
	position: absolute;
	width: 17rem;
	height: 20rem;
	object-fit: cover;
	right: 8rem;
	top: 2rem;
	
}

.right-t>:nth-child(1){

	position: absolute;
	width: 17rem;
	height: 20rem;
	border: 2px solid orange;
	background-color: transparent;
	right: 9rem;
	top: 0.9rem;
	
	
}

.right-t>:nth-child(2){

	position: absolute;
	width: 17rem;
	height: 19rem;
	right: 7rem;
	top: 4rem;
	background: var(--planCard);
	
	
}


.arrows{
	display: flex;
	gap: 1rem;
	position: absolute;
	bottom: 1rem;
	left: 3rem;
	height: 2rem;
	
}

.arrows>img{
	width: 1.5rem;
	cursor: pointer;
}

