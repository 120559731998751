.imag {
	right: 0rem;
	width: 0rem;
	
}

.Utilidades{
	padding: 0 2rem;
	display: flex;
	grip-template: 2rem;
}

.left-r{
	flex: 1 1;
	display: grid;
	grid-template-columns: repeat(3,1fr);
	gap: 1rem;
	grid-auto-rows: 1fr;
}

.right-r{
	flex: 1 1;
	text-transform: uppercase;
	gap: 1rem;
	display: flex;
	flex-direction: column;

}


.right-r>span{
		font-weight:bold;
	}

	.right-r>div{
		display:flex;
		gap: 1rem;
		color: white;
		font-size: 1.1rem;
		font-weight: bold;
		font-style: italic;
		
	}
	.details-r{
		display:flex;
		flex-direction: column;
		gap: 1rem;
	}


	.details-r>{
		display:flex;
		font-size:1rem;
		gap: 1rem;
	}
	
	.details-r>div>img{
		width: 1.4rem;
		height: 1.4rem;
	}
	
	



