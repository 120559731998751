.c1{
	padding: 2px;
	gap: 1rem;
	width: 9rem;
	height: 9rem;
	
	
}


.left-x{color: white;
	font-size:3rem;
	font-weight: bold;
	text-transform: uppercase;
	position: relative;}
