.header{
	display : flex;
	justify-content: space-between;

}

.logo {
	width: 13rem;
	height: 5rem;
}

.header-menu{
	list-style: none;
	display: flex;
	gap: 1.5rem;
	color: white;
}

.header-menu>li{
	cursor: pointer;
}

.header-menu>li:hover{
	cursor: pointer;
	color: var(--orange);
	cursor: pointer;
}