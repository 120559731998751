.hero{
	display: flex;
	justify-content: space-between;

}

.left-h{
	padding: 2rem;
	padding-top: 1.5rem;
	flex:3;
	display: flex;
	gap: 2rem;
	flex-direction : column;
}

.right-h{
	flex:1;
	position: realative;
	background-color:var(--orange);
	
}

.the-best-ad{
	margin-top: 4rem;
	background-color: #363d42;
	border-radius: 4rem;
	width: fit-content;
	padding: 20px 13px;
	text-transform: uppercase;
	color: white;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.the-best-ad>span{
	z-index: 2;

}

.the-best-ad>div{
	position: absolute;
	background-color: var(--orange);
	width: 5.4rem;
	height: 80%;
	left: 8px;
	border-radius: 3rem;
	z-index: 1;

}

.hero-text{
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	text-transform: uppercase;
	font-size: 4.5rem;
	font-weight: bold;
	color: white;
	text-overflow: inherit;


}

.hero-text>div:nth-of-type(3){
	font-size: 1rem;
	font-weight: 200;
	text-transform: none;
	letter-spacing: 1px;
	width:	80%;
}

.figures{
	display: flex;
	gap: 1rem;
}
.figures>div{
	display: flex;
	flex-direction: column;
}

.figures>div>span:nth-of-type(1){
	color: white;
	font-size: 1.5rem;
}

.figures>div>span:nth-of-type(2){
	color: var(--gray);
	text-transform: upercase;
}

.hero-buttons{
	display: flex;
	gap: 1rem;
	font-weight: normal;
}

.hero-buttons>:nth-child(1){

	color: white;
	background-color: var(--orange);
	width: 8rem;
}

.hero-buttons>:nth-child(2){

	color: white;
	background-color: transparent;
	width: rem;
	border: 2px solid var(--orange);
}

.right-h>.btn{
	position: absolute;
	right: 3rem;top: 2rem;color: black;
}
.grupo {

	width: 5rem;
	height:4.5rem;
	border-radius:5px;
}

.grupo-a{
	display:flex;
	flex-direction:column;
	gap:1rem;
	background-color: var(--darkGrey);
	width: fit-content;
	padding: 0.5rem;
	align-items: start;
	border-radius:5px;
	position: absolute;
	right: 4rem;
	top:7rem;

}

.grupo-a>:nth-child(2){

	color: white;

}


.hero-image{
	position:absolute;
	top: 10rem;
	right: 13rem;
	width: 25rem;
}

.hero-image-back{
	position:absolute;
	top: 4rem;
	right: 25rem;
	z-index: -1;
	width: 23rem;
}


.metris-a{
	width: 5rem;
	height:4.5rem;
	border-radius:5px;
}
.metris >img {
	width: 5rem;
}
.metris >div{
	display: flex;
	flex-direction: column;
	justify-content: space-between;

}

.metris >div >:nth-child(1){
	color: var(--gray);
}
.metris >div >:nth-child(2){
	color: white;
}
.metris >div >:nth-child(3){
	color: white;
}

.hero-blur{
	width: 22rem;
	height: 30rem;
	left: 0;
}